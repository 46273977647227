import React from "react";
import { injectIntl } from 'gatsby-plugin-intl';
import Markdown from 'react-remarkable';

import Layout from "../../components/Layout";
import GenericPageTemplate from "../../templates/generic-page";

const CompliancePage = ({intl}) => {
  return (
    <Layout>
      <GenericPageTemplate
        heroTitle={intl.formatMessage({id: 'compliance.hero.title'})}
        heroDescription={intl.formatMessage({id: 'compliance.hero.text'})}
      >
      <Markdown>{intl.formatMessage({id: 'compliance.text'})}</Markdown>
      </GenericPageTemplate>
    </Layout>
  );
};

export default injectIntl(CompliancePage);
