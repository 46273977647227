import React from "react";
import { Row, Col } from "react-bootstrap";

import Background from "../components/Background";
import Section from "../components/Section";
import Hero from "../components/Hero";
import MotifColumn from "../components/MotifColumn";
import Questions from "../components/Questions";

class GenericPageTemplate extends React.Component {
	render() {
		return (
			<Background>
				<Hero>
					<MotifColumn>
						<h1>{this.props.heroTitle}</h1>
						{this.props.heroDescription && (
							<h2>{this.props.heroDescription}</h2>
						)}
					</MotifColumn>
				</Hero>
				<Section extraLeftPadding={true}>
					<Row>
						<Col xs={12} lg={8}>
							{this.props.children}
						</Col>
					</Row>
				</Section>
				<Questions />
			</Background>
		);
	}
}

export default GenericPageTemplate;
